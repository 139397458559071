import createPlugin, { LazyComponentsMap, PluginConstructorParams } from './create-plugin';

type ThemeConstructorParams = PluginConstructorParams & {
    page: LazyComponentsMap['page']
}

export default function createTheme({ page, components, ...others }: ThemeConstructorParams) {
    return createPlugin({
        components: {
            ...components,
            page,
        },
        ...others
    });
}
